









import PdfViewer from "@/components/files/PdfViewer.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import DocumentTemplateMixin from "@/mixins/DocumentTemplateMixin.vue";
import { DocumentModule } from "@/store/modules/document.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
const pdfMeUi = () => import("@pdfme/ui");

@Component({
  components: { LayoutSimple, PdfViewer },
  filters: {}
})
export default class DocumentTemplateDetailDebug extends mixins(DocumentTemplateMixin) {
  designer: any = null;

  async mounted() {
    const pdfMe = await pdfMeUi();
    const domContainer = document.getElementById("container");
    if (!domContainer) {
      throw Error("error no dom container");
    }

    const template = {
      basePdf: pdfMe.BLANK_PDF,
      schemas: []
    };

    const designer = new pdfMe.Designer({ domContainer, template });

    this.designer = designer;
  }

  async setTemplate() {
    const pdfMe = await pdfMeUi();
    this.designer?.updateTemplate({
      basePdf: DocumentModule.maps.ids.get(this.$route.params.documentId)[0].url ?? pdfMe.BLANK_PDF,
      schemas: this.schemas
    });
  }
}
